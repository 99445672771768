<style>
  @media (max-width: 1024px) {
    .sm-spacer {
      margin-top: .5rem;
    }
  }
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  .c-dashboard-row {
    margin-top: 1px;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
  .c-dashboard-col {
    padding-left: unset;
    padding-right: unset;
    height: 100% !important;
  }
  .c-dashboard-card {
    border-radius: 0 !important;
    border-left: 1px solid #222736 !important;
    height: 100% !important;
    padding: unset;
  }
  @media (max-width: 1024px) {
    .c-players-col {
      height: 100% !important;
      width: 100%;
      float: left;
    }
    .c-feed-col {
      height: 100% !important;
      float: left;
      width: 100%;
      border-top: 2px solid #222736 !important;
    }
    .c-player-connection {
      display: none;
    }
    .c-player-names {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .c-player-badge {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    .c-players-col {
      height: 100% !important;
      border-right: 2px solid #222736 !important;
      /* width: 70rem; */
      width: 67%;
      float: left;
    }
    .c-feed-col {
      margin-left: 0.5rem;
      height: 100% !important;
      /* border-left: 1px solid #222736 !important; */
      float: left;
      /* width: 33.5rem; */
      width: 32.2%;
    }
    .c-player-connection {
      margin-left: 1rem;
      margin-right: 1rem;
      width: 9rem;
    }
    .c-player-names {
      margin-right: 1rem;
      margin-left: 1rem;
      width: 9rem;
    }
    .c-player-playtime {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .c-player-badge {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      margin-top: 0.5rem;
    }
  }
  .c-player {
    margin-bottom: 0 !important;
    border-bottom: 1px solid #222736 !important;
  }
  .c-intersecting-row {
    position: relative;
    left: 0.5rem;
    top: -1.75rem;

  }
  .c-player-kdratio {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .c-admins {
    margin-top: -2rem !important;
    margin-left: 4rem;
    margin-bottom: -1.5rem;
  }
  .c-pin-btn {
    margin-bottom: -1.25rem;
  }
  .c-feed-container {

  }
  .c-player-row {
    height: 2rem;
  }

  .c-player-img {
    width: 2rem;
  }

  .c-player-ping {
    /*width: 5rem;*/
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .c-feed {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .c-feed-text {
    word-break: break-all;
  }
  .c-feed-row:hover {
    cursor: pointer;
    filter: brightness(150%);
  }
  .c-console-header {
    padding: 0.5rem !important;
  }
  .c-console-container {
    padding: unset !important;
    height: 25rem;
  }
  .c-console-messages {
    max-height: 92%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .c-console-message {
    white-space: pre-line;
  }
  .c-console {
    background: black;
    color: white;
    height: 100%;
    position: relative;
  }
  .c-console-prompt-row {
    position:absolute;
    bottom:0;
    width: 100%;
  }
  .c-console-prompt {
    background: black;
    border: none;
    outline: none;
    color: white !important;
    width: 95%;
    display: inline-block;
    caret-shape: block;
  }
  .c-console-prompt-header {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    display: inline-block;
  }
  .leaflet-container {
    background: unset;
    outline: 0;
  }
  .c-player-marker {
    box-shadow: 1px 1px 1px grey;
    -moz-box-shadow: 1px 1px 1px grey;
    -webkit-box-shadow: 1px 1px 1px grey;
    z-index: 901 !important;
  }
  .c-vehicle-marker {
    z-index: 500 !important;
  }
  .c-vehicle-marker-driving {
    z-index: 500 !important;
    text-shadow: 0 0 3px black;
    color: #4287f5;
  }
  .c-vehicle-marker i {
    text-shadow: 0 0 3px black;
  }
  .c-event-marker {
    z-index: 499 !important;
  }
  .c-event-marker i {
    color: #e83e8c;
    text-shadow: 0 0 3px black;
  }
  .c-highlight-marker {
    z-index: 999999999999 !important;
    color: #ff00f7;
    text-shadow: 0 0 3px black;
  }
  .c-truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .leaflet-container {
    width: 100vw;
    height: 100vh;
  }
</style>
<script>

import {mapGetters} from "vuex";
import {WorkerState} from "@/enums";
import {parseISO8601String, get_acsrf_token} from "@/methods";
import { HalfCircleSpinner } from 'epic-spinners';
import CopyableText from "@/components/CopyableText";

const humanizeDuration = require("humanize-duration");
const customHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});

const uniqueElementsBy = (arr, fn) =>
    arr.reduce((acc, v) => {
      if (!acc.some(x => fn(v, x))) acc.push(v);
      return acc;
    }, []);

if (!String.format) {
  String.format = function(format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined'
          ? args[number]
          : match
          ;
    });
  };
}

export default {
  props: ['server_id', 'options', 'is_mobile'],
  components: {
    HalfCircleSpinner,
    CopyableText
  },
  computed: {
    ...mapGetters([
      'getServer',
      'getUILanguage',
      'getPersonaName',
      'getPersonaAvatar',
      'getAccountId',
      'getDTLocale',
      'getPreferences'
    ]),
    filteredPlayers: function() {
      let players = [];
      if(this.searchQuery) {
        this.players.forEach((player) => {
          let relevant = false;
          if(player.persona.profile.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || player.persona.profile.name.toLowerCase() === this.searchQuery.toLowerCase()) relevant = true;
          else if(player.gamedata.player_name.toLowerCase().includes(this.searchQuery.toLowerCase()) || player.gamedata.player_name.toLowerCase() === this.searchQuery.toLowerCase()) relevant = true;
          //else if(player.connection.ipv4.includes(this.searchQuery.toLowerCase()) || player.connection.ipv4 === this.searchQuery.toLowerCase()) relevant = true;
          if(relevant) players.push(player);
        });
      } else {
        players = this.players;
      }
      let k = 'created_at';
      switch(this.sortField) {
        case 'playtime': {
          k = 'created_at';
          break;
        }
        case 'name': {
          // gamedata.player_name
          players = players.sort((a, b) => {
            if(a.gamedata.player_name < b.gamedata.player_name) return -1 * this.sortDirection;
            if(a.gamedata.player_name > b.gamedata.player_name) return 1 * this.sortDirection;
            return 0;
          });
          return players;
        }
      }
      players = players.sort((a, b) => {
        if(a[k] < b[k]) return -1 * this.sortDirection;
        if(a[k] > b[k]) return 1 * this.sortDirection;
        return 0;
      });
      return players;
    },
    reversedFeed: function() {
      return this.feed.slice().reverse();
    }
  },
  methods: {
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    adjustSize() {
      try {
        this.height = visualViewport.height - 70 - 60 - 65;
        this.width = visualViewport.width;
      } catch (e) {
        this.height = (window.outerHeight - 70 - 60 - 65) * 0.87;
        this.width = (window.outerWidth);
      }
      if(this.width < 1024) {
        this.mobileView = true;
        this.feedHeight = this.height - 100;
      } else {
        this.mobileView = false;
        this.feedHeight = this.height - 100;
      }
    },
    playerPlaytime(date, created_at) {
      if(this.mobileView) return customHumanizer(date - created_at.getTime(), { round: true, largest: 2, units: ['d', 'h', 'm'], language: 'shortEn' });
      return customHumanizer(date - created_at.getTime(), { round: true, largest: 2, units: ['d', 'h', 'm'], language: 'shortEn' });
    },
    displayEvent(event) {
      let blockedEvents = ['user.loaded', 'rcon.console', 'server.state'];
      return !blockedEvents.includes(event.event);
    },
    localizeEvent(event, includePositions = true) {
      switch(event.event) {
        case 'user.join': return this.$t('server.dashboard.feed.events.user.join');
        case 'user.leave': return this.$t('server.dashboard.feed.events.user.leave', {playtime: humanizeDuration(event.parameters.player.playtime * 1000)});
        case 'user.kicked': return this.$t('server.dashboard.feed.events.user.kicked', {
          reason: event.parameters.player.reason, playtime: humanizeDuration(event.parameters.player.playtime * 1000)
        });
        case 'user.chat': return `(${event.parameters.chat.channel}) > ${event.parameters.chat.message}`;
        case 'server.message': {
          return this.$t('events.server.message', event.parameters);
        }
        case 'server.directmessage': {
          return this.$t('events.server.message', event.parameters);
        }
        default: return event.event + ' | ' + event.parameters;
      }
    },
    showKickModal(gamesessionId) {
      this.kick.target = gamesessionId;
      this.kick.reason = null;
      this.$refs.kickModal.show();
    },
    showKickAllModal() {
      this.$refs.kickAllModal.show();
    },
    async kickAllPlayers() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          reason: this.kickAll.reason
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/kick-all`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.kickAll.reason = null;
          this.$toast.success(this.$t('server.dashboard.players.kickall.success'));
          this.$refs.kickAllModal.hide();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async kickPlayer() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          gamesession_id: this.kick.target,
          reason: this.kick.reason
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/kick-player`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.kick.target = null;
          this.kick.reason = null;
          this.$toast.success(this.$t('server.dashboard.players.kick.success'));
          this.$refs.kickModal.hide();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    showMessageModal(gamesessionId) {
      this.message.target = gamesessionId;
      this.message.reason = null;
      this.$refs.messageModal.show();
    },
    async messagePlayer() {
      if(!this.message.content || this.message.content.length === 0) return;
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          gamesession_id: this.message.target,
          content: this.message.content
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/message-private`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.message.target = null;
          this.message.content = null;
          this.$toast.success(this.$t('server.dashboard.players.message.success'));
          this.$refs.messageModal.hide();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    toggleConsole() {
      this.$refs.consoleModal.show();
      setTimeout(() => {
        try {
          this.$refs.consoleMessages.scrollTop = this.$refs.consoleMessages.scrollHeight;
          // eslint-disable-next-line no-empty
        } catch(e) {}
        this.$refs.consolePrompt.select();
      }, 1);
    },
    toggleServerPanel() {
      this.$refs.serverModal.show();
    },
    async consoleEnterHandler() {
      if(this.console.command === 'clear') {
        this.console.command = null;
        this.console.messages = [];
        return;
      }
      this.$refs.consolePrompt.disabled = true;
      this.console.pending = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          command: this.console.command
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/console`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          if(this.console.command.charAt(0) === '#') this.console.pending = false;
          else {
            setTimeout(() => {
              this.console.pending = false;
            }, 5000);
          }
          this.console.command = null;
          setTimeout(() => {
            this.$refs.consolePrompt.select();
          }, 1);
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.console.pending = false;
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.$refs.consolePrompt.disabled = false;
    },
    async messageServer() {
      if(!this.serverMessage || this.serverMessage.length === 0) return;
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          content: this.serverMessage
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/message-server`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.feed.send.success'));
          this.serverMessage = null;
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async toggleLock() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/state-lock`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.server.lock.success'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async toggleWhitelist() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/state-whitelist`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.server.whitelist.success'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async skipShutdown() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/shutdown-skip`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.server.shutdown.skipped'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async shutdownNow() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/shutdown-now`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.server.shutdown.success'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async scheduleShutdown() {
      this.$refs.serverModal.hide();
      let result = await this.$swal({
        input: 'text',
        title: this.$t('server.dashboard.server.shutdown.prompt'),
        showCancelButton: true,
        confirmButtonText: this.$t('server.dashboard.server.shutdown.scheduled'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
        showLoaderOnConfirm: true,
        preConfirm: async (shutdown_delay) => {
          let acsrf_token = await get_acsrf_token();
          let payload = {
            acsrf_token: acsrf_token,
            delay: shutdown_delay
          };
          let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/shutdown-delayed`, {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          });
          if(response.ok) {
            return response.json();
          }
          this.$swal.showValidationMessage(this.$t('error.server.generic.message'));
        }
      }).then((result) => {
        if(result.isConfirmed) {
          this.$toast.success(this.$t('server.dashboard.server.shutdown.success'));
        }
      });
    },
    async refreshPlayers() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/GSM`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.players = [];
          data.sessions.forEach((player) => {
            player.created_at = parseISO8601String(player.created_at);
            this.players.push(player);
          });
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
    },
    async fetchFeed() {
      if(this.feedLoading === true) return;
      this.feedLoading = true;
      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/feed`);
        url.searchParams.append('index', this.feedPosition);
        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();

          this.feedMax = data.max;
          data.entries.forEach((entry) => {
            entry.date = parseISO8601String(entry.date);
            this.feed.splice(this.feedPosition, 0, entry);
            this.feedPosition++;
          });

          await this.refreshFeed();

        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.feedLoading = false;
    },
    getPlayer(sessionId) {
      let target = null;
      this.players.forEach((player) => {
        if(player.id === sessionId) {
          target = player;
          return;
        }
      });
      return target;
    },
    getPlayerByCFID(cftoolsId) {
      let target = null;
      this.players.forEach((player) => {
        if(player.cftools_id === cftoolsId) {
          target = player;
          return;
        }
      });
      return target;
    },
    removePlayer(sessionId) {
      let players = [];
      this.players.forEach((player) => {
        if(player.id !== sessionId) players.push(player);
      });
      this.players = [];
      this.players = players;
    },
    /* ***** Event Handler ***** */
    async handleFeedEvent(event) {
      if(event.parameters.server.id !== this.server_id) return;
      let entry = Object.assign({}, event);

      this.feedMax++;
      entry.date = parseISO8601String(entry.date);
      this.feed.splice(0, 0, entry);
      this.feedPosition++;
      await this.refreshFeed();
      this.scrollFeedToBottom(false);

      if(event.event === 'user.chat') {
        if(this.preferences.audio_alert_admin) {
          if(event.parameters.chat.message.toLowerCase().includes('admin')) {
            this.audio.audio_alert_admin.play();
          }
        }
      }

      if(event.event === 'rcon.console') {
        this.console.messages.push({id: entry.id, message: event.parameters.console.message});
        this.console.pending = false;

        setTimeout(() => {
          try {
            this.$refs.consoleMessages.scrollTop = this.$refs.consoleMessages.scrollHeight;
            // eslint-disable-next-line no-empty
          } catch(e) {}
        }, 1);
      }
      // TODO: Test in production
      this.$nextTick(() => {
        let percentageTop = this.feedScrollLevel();
        if(percentageTop <= 20 && this.feedPosition > 100) {
          let startDeleteIndex = Math.max(100, this.feedPosition * 0.5);
          // console.log('Deleting up from', startDeleteIndex);
          this.feed.splice(startDeleteIndex, this.feedPosition - startDeleteIndex - 1);
          this.feedPosition = startDeleteIndex;
        }
      });

    },
    async handleServerUpdate(data) {
      if(data.server_id !== this.server_id) return;
      if(data.type === 'players') {
        data.players.forEach((playerData) => {
          let player = this.getPlayer(playerData.gamesession.id);
          if(player === null) return;
          player.live.ping = playerData.ping;
          player.live.loaded = playerData.connection.loaded;
        });
      } else if(data.type === 'player') {
        let player = this.getPlayer(data.gamesession.id);
        if(player) Object.assign(player.live, data.player.live);
      } else if(data.type === 'server') {
        this.server = data.server;
      }
    },
    appendFeed(event, parameters) {
      this.feedMax++;
      let entry = {
        date: new Date(),
        event: event,
        pos: this.feedPosition,
        parameters: parameters
      };
      this.feed.splice(0, 0, entry);
      this.feedPosition++;
      this.refreshFeed();
    },
    async refreshFeed() {
      let feedContent = this.feed;
      this.feed = [];
      this.feed = uniqueElementsBy(feedContent,(a, b) => a.id == b.id);
    },
    async onPlayerJoin(data) {
      if(data.server.id !== this.server_id) return;
      if(this.getPlayer(data.gamesession.id) !== null) {
        this.$toast.error(`ERROR: Potential player desync or websocket lag (0x0)`);
        this.removePlayer(data.gamesession.id);
      }
      let player = {
        id: data.gamesession.id,
        cftools_id: data.user.id,
        created_at: parseISO8601String(data.created_at),
        persona: {},
        connection: {
          ipv4: null,
          provider: null,
          country_code: null,
          country_names: {},
          malicious: data.details.connection.vpn
        },
        gamedata: {
          player_name: data.details.player_name
        },
        live: {
          ping: {
            actual: null,
            trend: 0
          },
          loaded: false
        },
        info: data.info
      }

      if(this.permissions.pi) {
        player.connection.ipv4 = data.details.ipv4;
        player.connection.provider = data.details.connection.provider;
        player.connection.country_code = data.details.connection.country_code;
      }
      this.players.push(player);
    },
    async onPlayerUpdate(data) {
      if(data.server.id !== this.server_id) return;
      let player = this.getPlayer(data.gamesession.id);
      if(player === null) {
        this.$toast.error(`ERROR: Potential player desync or websocket lag (0x1)`);
      } else {
        player.persona = {
          profile: data.profile,
          bans: data.bans
        }
      }
    },
    async onPlayerLeave(data) {
      if(data.server.id !== this.server_id) return;
      let player = this.getPlayer(data.gamesession.id);
      if(player !== null) {
        this.removePlayer(data.gamesession.id);
      }
      if(this.players.length === 0) {
        this.$nextTick(() => {
          this.refreshGameLabsPlayers();
        });
        setTimeout(() => {
          this.refreshGameLabsPlayers();
        }, 1000 * 10);
      }
    },
    /* ************************* */
    scrollFeedToBottom(force) {
      if(this.feedPinned && force !== true) return;
      try {
        this.$refs.feed.scrollTop = this.$refs.feed.scrollHeight;
        // eslint-disable-next-line no-empty
      } catch(e) {}
    },
    async scrollFeedTo(percentage) {
      let target = this.$refs.feed.scrollHeight - ((percentage / 100) * this.$refs.feed.scrollHeight);
      this.$refs.feed.scrollTop = target;
    },
    async handleScrolling() {
      let percentageTop = 100 - ((this.$refs.feed.scrollTop / this.$refs.feed.scrollHeight)*100);
      if(percentageTop >= 95) {
        if(this.feedPosition < this.feedMax) {
          await this.scrollFeedTo(94);
          await this.fetchFeed();
          await this.scrollFeedTo(94);
        }
      }
    },
    feedScrollLevel() {
      return 100 - ((this.$refs.feed.scrollTop / this.$refs.feed.scrollHeight)*100);
    },
    feedMouseEnter(event_id) {
      let data;
      this.feed.forEach((e) => {
        if(e.id === event_id) {
          data = JSON.parse(JSON.stringify(e));
          return;
        }
      });
      if(!data) return;
      let tooltip;
    },
    feedMouseLeave() {

    },
    async onContextMenu(event) {
      event.preventDefault();
    },
    async onPresenceChange(event) {
      if(!event.server_id) return;
      if(event.server_id !== this.server_id) return;
      let cftools_id = event.cftools_id;
      if(this.admins[cftools_id] && event.status === 0) {
        delete this.admins[cftools_id];
      } else {
        this.admins[cftools_id] = {
          date: new Date(),
          avatar: event.arguments.avatar,
          name: event.arguments.name
        };
        if(event.status === 1) {
          this.$socket.client.emit('presence', {type: 'server', server_id: this.server_id, status: 2, arguments: {avatar: this.getPersonaAvatar(), name: this.getPersonaName()}});
        }
      }
      this.visibleAdmins = [];
      this.visibleAdmins = Object.keys(this.admins);
    },
    async selectPlayer(player) {
      if(this.player && this.player === player) this.player = null;
      else this.player = player;
    },
    updateConnectionStatus(status) {
      this.serverStatus = status;
    }
  },
  async created() {
    this.preferences = this.getPreferences();
    if(this.timer === null) {
      this.timer = setInterval(() => {
        this.visibleAdmins = [];
        this.$socket.client.emit('presence', {type: 'server', server_id: this.server_id, status: 1, arguments: {avatar: this.getPersonaAvatar(), name: this.getPersonaName()}});
        this.currentDate = new Date();
        Object.keys(this.admins).forEach((cftools_id) => {
          if(new Date() - this.admins[cftools_id].date > 2 * 60 * 1000) {
            delete this.admins[cftools_id];
          }
        });
        this.visibleAdmins = Object.keys(this.admins);
      }, 60 * 1000);
    }
    this.console.version = this.options.console.version;
    this.console.commands = this.options.console.commands;

    this.server = this.options.server;
    this.features = this.options.features;
    Object.keys(this.features['available-actions']).forEach((i) => {
      let action = this.features['available-actions'][i];
      if(action.actionContext === 'player') {
        this.playerContextActions.push({
          name: action.actionName,
          data: action
        })
      } else if(action.actionContext === 'vehicle') {
        this.vehicleContextActions.push({
          name: action.actionName,
          data: action
        })
      } else if(action.actionContext === 'world') {
        this.worldContextActions.push({
          name: action.actionName,
          data: action
        })
      }
    });

    this.permissions = this.options.permissions;
    this.adjustSize();
    window.addEventListener("resize", this.adjustSize);
    await this.refreshPlayers();
    await this.fetchFeed();
    this.scrollFeedToBottom(true);

    this.$socket.client.on('server:feed', this.handleFeedEvent); // Update from server (Omega)
    this.$socket.client.on('server:update', this.handleServerUpdate); // Update from server (Omega)
    this.$socket.client.on('gsm:player:create', this.onPlayerJoin); // player join
    this.$socket.client.on('gsm:player:persona', this.onPlayerUpdate); // player join
    this.$socket.client.on('gsm:player:destruct', this.onPlayerLeave); // player leave

    this.$socket.client.on('presence:server', this.onPresenceChange);
  },
  mounted() {
    this.$socket.client.emit('join', {type: 'active', server_id: this.server_id});
    this.$socket.client.emit('join', {type: 'metrics', server_id: this.server_id});
    this.audio.audio_alert_admin.volume = 0.05;

    this.adjustSize();

    if(!this.admins[this.getAccountId()]) {
      this.admins[this.getAccountId()] = {
        date: new Date(),
        avatar: this.getPersonaAvatar(),
        name: this.getPersonaName()
      }
      this.visibleAdmins.push(this.getAccountId());
    }
    this.$nextTick(() => {
      this.$socket.client.emit('presence', {type: 'server', server_id: this.server_id, status: 1, arguments: {avatar: this.getPersonaAvatar(), name: this.getPersonaName()}});
    });
  },
  destroyed() {
    this.$socket.client.off('server:feed', this.handleFeedEvent); // Update from server (Omega)
    this.$socket.client.off('server:update', this.handleServerUpdate); // Update from server (Omega)
    this.$socket.client.off('gsm:player:create', this.onPlayerJoin); // player join
    this.$socket.client.off('gsm:player:persona', this.onPlayerUpdate); // player join
    this.$socket.client.off('gsm:player:destruct', this.onPlayerLeave); // player leave
    this.$socket.client.off('presence:server', this.onPresenceChange);
    this.$socket.client.emit('presence', {type: 'server', server_id: this.server_id, status: 0});
    this.$socket.client.emit('leave', {type: 'active', server_id: this.server_id});
    this.$socket.client.emit('leave', {type: 'metrics', server_id: this.server_id});
    if(this.timer !== null) clearInterval(this.timer);
  },
  watch: {
    '$socket.connected'() {
      if(!this.$socket.connected && this.connected) {
          this.connected = false;
      } else if(this.$socket.connected && !this.connected) {
        this.$socket.client.emit('join', {type: 'active', server_id: this.server_id});
        this.$socket.client.emit('join', {type: 'metrics', server_id: this.server_id});
      }
    }
  },
  data() {
    return {
      WorkerState:WorkerState,
      preferences: {},
      audio: {
        audio_alert_admin: new Audio(require('@/assets/audio/error.wav'))
      },
      connected: true,
      serverStatus: true,
      player: null,
      admins: {},
      visibleAdmins: [],

      timer: null,
      currentDate: new Date(),
      ready: true,
      error: false,
      mobileView: false,
      height: 0,
      width: 0,
      feedHeight: 0,
      feedMax: 0,
      feedPosition: 0,
      displayMode: 0,
      searchQuery: null,
      sortField: 'playtime',
      sortDirection: -1,
      players: [],
      feed: [],
      feedPinned: false,
      feedLoading: false,
      features: {
        map: false
      },
      permissions: {
        feed: false,
        map: false
      },
      server: {},

      // Actions
      inProgress: false,
      serverMessage: null,
      kick: {
        target: null,
        reason: null
      },
      kickAll: {
        reason: null
      },
      message: {
        target: null,
        content: null
      },

      // Special
      console: {
        pending: false,
        command: null,
        version: null,
        commands: ['help', 'version'],
        messages: []
      }
    }
  }
};
</script>

<template>
  <div ref="coreElement" style="overflow-y: hidden !important;">
    <template v-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="c-dashboard-row" :style="mobileView ? {} : {'height': height+'px'}">
        <div class="c-dashboard-col">
          <!-- Main Server Dashboard -->
          <div class="card card-body c-dashboard-card" v-if="displayMode === 0">
            <div class="c-dashboard-row">
              <div class="c-players-col" style="padding-right: unset;">
                <b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted w-100 h-100" class="w-100 h-100" ref="dashboardTabs">
                  <b-tab active :style="{'height': (height-35)+'px'}" class="overflow-auto">
                    <template v-slot:title class="border border-danger">
                      <span class="d-inline-block">
                        <i class="fas fa-users" />
                      </span>
                      <span class="d-none ml-1 d-sm-inline-block">
                          {{ $t('server.dashboard.players.title') }}
                      </span>
                    </template>
                    <template>
                      <div class="row mt-1 mb-1 ml-1 mr-3 pr-0 pl-0">
                        <div class="col-lg-3 col-sm-12">
                          <b-input-group size="sm" :prepend="$t('server.dashboard.players.search')">
                            <b-form-input v-model="searchQuery"></b-form-input>
                          </b-input-group>
                        </div>
                        <div class="col-lg-3 col-sm-12 sm-spacer">
                          <b-input-group size="sm" :prepend="$t('server.dashboard.players.sort.label')">
                            <b-form-select v-model="sortField">
                              <b-form-select-option value="playtime" selected="selected">
                                {{ $t('server.dashboard.players.sort.options.playtime') }}
                              </b-form-select-option>
                              <b-form-select-option value="name">
                                {{ $t('server.dashboard.players.sort.options.name') }}
                              </b-form-select-option>
                            </b-form-select>
                            <b-input-group-append>
                              <template v-if="sortDirection === -1">
                                <button class="btn btn-outline-primary btn-sm" v-on:click="sortDirection = 1;">
                                  <i class="fal fa-arrow-down" />
                                </button>
                              </template>
                              <template v-else>
                                <button class="btn btn-outline-primary btn-sm" v-on:click="sortDirection = -1;">
                                  <i class="fal fa-arrow-up" />
                                </button>
                              </template>

                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </div>
                      <div role="tablist">
                        <b-card no-body class="settings-collapse c-player hard-border" v-for="player in filteredPlayers" :key="player.id">
                          <a v-b-toggle="player.id" class="text-dark" href="javascript: void(0);" v-on:click="selectPlayer(player)">
                            <b-card-header header-tag="header" role="tab">
                              <div class="row c-player-row">
                                <div class="c-player-img">
                                  <template v-if="player.persona.profile">
                                    <img :src="player.persona.profile.avatar" class="rounded-circle header-profile-user">
                                  </template>
                                  <template v-else>
                                    <half-circle-spinner :animation-duration="900" :size="32" class="align-middle"/>
                                  </template>
                                </div>
                                <div class="c-player-names">
                                  <div class="c-truncate-text">
                                    <span>
                                      <CopyableText :text="player.gamedata.player_name"/>
                                    </span>
                                    <template v-if="player.connection.country_code !== null">
                                      <flag
                                          :iso="player.connection.country_code"
                                          :title="player.connection.country_names[getUILanguage()]"
                                          :squared="false"
                                      />
                                    </template>
                                  </div>
                                  <div class="small text-muted c-truncate-text" v-if="player.persona.profile">
                                    <i class="fab fa-steam"></i>
                                    <span>
                                      <CopyableText :text="player.persona.profile.name"/>
                                    </span>
                                  </div>
                                </div>
                                <template v-if="permissions.pi">
                                  <div class="c-player-connection">
                                    <b class="text-code">
                                      <CopyableText class="text-code" :text="player.connection.ipv4"/>
                                    </b>
                                    <div class="small text-muted c-truncate-text">
                                      <span>
                                        <CopyableText :text="player.connection.provider"/>
                                      </span>
                                    </div>
                                  </div>
                                </template>
                                <template v-if="player.live.ping && player.live.ping.actual !== null && player.live.ping.actual >= 0">
                                  <div class="c-player-ping">
                                    <b class="text-code">
                                      {{ player.live.ping.actual }}ms
                                      <template v-if="player.live.ping.trend === 1">
                                        <i class="fal fa-chevron-double-up text-danger" />
                                      </template>
                                      <template v-else-if="player.live.ping.trend === -1">
                                        <i class="fal fa-chevron-double-down text-success" />
                                      </template>
                                      <template v-else>
                                        <!-- TODO: Icon for stable ping? -->
                                      </template>
                                    </b>
                                    <div class="small text-muted">
                                      {{ $t('server.dashboard.players.ping') }}
                                    </div>
                                  </div>
                                </template>
                                <div class="c-player-playtime">
                                  <b class="text-code">
                                    {{ playerPlaytime(currentDate, player.created_at) }}
                                  </b>
                                  <div class="small text-muted">
                                    {{ $t('server.dashboard.players.playtime') }}
                                  </div>
                                </div>
                                <template v-if="player.live && player.live.loaded === false">
                                  <div class="c-player-badge text-center">
                                      <span class="badge badge-primary text-black font-size-12">
                                        {{ $t('server.dashboard.players.loading') }}...
                                      </span>
                                  </div>
                                </template>
                                <template v-if="player.persona.profile">
                                  <template v-if="player.persona.bans.vac > 0">
                                    <div class="c-player-badge text-center">
                                      <span class="badge badge-danger text-black font-size-12">
                                        {{ player.persona.bans.vac }} {{ $t('server.dashboard.players.vac') }}
                                      </span>
                                    </div>
                                  </template>

                                  <template v-if="player.persona.profile && player.persona.profile.private === true">
                                    <div class="c-player-badge text-center">
                                      <span class="badge badge-warning text-black font-size-12">
                                        {{ $t('server.dashboard.players.private') }}
                                      </span>
                                    </div>
                                  </template>
                                </template>
                                <template v-if="player.info && player.info.ban_count > 0">
                                  <div class="c-player-badge text-center">
                                      <span class="badge badge-warning text-black font-size-12">
                                        {{ $t('server.dashboard.players.bans', {count: player.info.ban_count}) }}
                                      </span>
                                  </div>
                                </template>
                              </div>
                            </b-card-header>
                          </a>
                          <b-collapse :id="player.id" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                              <b-card-text>
                                <div class="row justify-content-center">
                                  <div class="col-lg-5 col-sm-12">
                                    <div class="table-responsive">
                                      <table class="table table-nowrap mb-0">
                                        <tbody>
                                          <tr>
                                            <th scope="row">
                                              <h6 class="text-uppercase mb-0">
                                                {{ $t('server.dashboard.players.joined') }}
                                              </h6>
                                            </th>
                                            <td>
                                              {{ $d(player.created_at, 'datetime', getDTLocale()) }}
                                            </td>
                                          </tr>
                                          <tr v-show="player.live && player.live.load_time">
                                            <th scope="row">
                                              <h6 class="text-uppercase mb-0">
                                                {{ $t('server.dashboard.players.load_time') }}
                                              </h6>
                                            </th>
                                            <td>
                                              {{ player.live.load_time }}s
                                            </td>
                                          </tr>
                                          <tr v-show="permissions.pi">
                                            <th scope="row">
                                              <h6 class="text-uppercase mb-0">
                                                IP
                                              </h6>
                                            </th>
                                            <td>
                                              {{ player.connection.ipv4 }}
                                              <div class="small text-muted">
                                                {{ player.connection.provider }}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="col-lg-5 col-sm-12 sm-spacer">
                                    <template>
                                      <router-link :to="{name: 'profile', params: {cftools_id: player.cftools_id}}" target="_blank">
                                        <button class="btn btn-block btn-outline-primary mb-2">
                                          {{ $t('server.dashboard.players.profile') }}
                                        </button>
                                      </router-link>
                                    </template>
                                    <template v-show="permissions.kick">
                                      <button class="btn btn-block btn-outline-warning" v-on:click="showKickModal(player.id)">
                                        {{ $t('server.dashboard.players.kick.button') }}
                                      </button>
                                    </template>
                                    <template v-show="permissions.message_private">
                                      <button class="btn btn-block btn-outline-info" v-on:click="showMessageModal(player.id)">
                                        {{ $t('server.dashboard.players.message.button') }}
                                      </button>
                                    </template>
                                  </div>
                                </div>
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                    </template>
                  </b-tab>

                  <b-tab class="h-100 w-100" v-if="is_mobile">
                    <template v-slot:title>
                      <div>
                        <span class="d-inline-block">
                          <i class="fas fa-list-alt" />
                        </span>
                        <span class="d-none ml-1 d-sm-inline-block">
                          Feed
                        </span>
                      </div>
                    </template>
                    <template v-if="permissions.message_server">
                      <div class="row mt-2">
                        <div class="col">
                          <div class="input-group bg-light">
                            <input type="text" v-on:keyup.enter="messageServer()" v-model="serverMessage" :placeholder="$t('server.dashboard.feed.send.placeholder')" class="form-control bg-transparent border-0">
                            <button class="btn btn-primary hard-border" v-on:click="messageServer()" :disabled="inProgress" :class="{'disabled': inProgress}">
                              <half-circle-spinner
                                  v-if="inProgress"
                                  :animation-duration="1200"
                                  :size="24"
                                  class="align-middle d-inline-block"
                              />
                              <template v-if="!inProgress">
                                <i class="fad fa-reply-all"></i>
                              </template>
                            </button>
                          </div>
                        </div>
                      </div>
                    </template>
                    <hr>
                    <template v-if="!permissions.feed">
                    <span class="text-muted text-uppercase">
                      {{ $t('server.dashboard.feed.permissions') }}
                    </span>
                    </template>
                    <template v-else>
                      <div class="c-intersecting-row">
                        <div class="c-pin-btn">
                          <button
                              class="btn btn-rounded btn-sm"
                              :class="{'btn-outline-info': !(feedPinned), 'btn-warning': (feedPinned), 'text-black': (feedPinned)}"
                              v-on:click="feedPinned = !(feedPinned)"
                          >
                            <i class="fad fa-map-pin" />
                            {{ $t('server.dashboard.feed.pin') }}
                          </button>
                        </div>
                        <div class="c-admins" v-if="this.visibleAdmins.length">
                          <div class="avatar-group">
                            <div class="avatar-group-item" v-for="cftools_id in this.visibleAdmins" :key="cftools_id">
                              <a
                                  :href="`/profile/${cftools_id}`"
                                  class="d-inline-block"
                                  target="_blank"
                              >
                                <img
                                    :src="admins[cftools_id].avatar"
                                    class="rounded-circle avatar-xs"
                                    v-b-tooltip.hover
                                    :title="admins[cftools_id].name"
                                    style="border:1px solid black"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="c-feed" :style="{'height': feedHeight+'px'}" ref="feed" v-on:scroll="handleScrolling">
                        <div class="row c-feed-row"
                             v-for="event in reversedFeed"
                             :key="event.id"
                             :style="{'display': displayEvent(event) ? null : 'none'}"
                             v-on:click="feedMouseEnter(event.id)"
                        >
                          <template v-if="displayEvent(event)">
                            <div class="col-3 text-center">
                              <small>
                                {{ $d(event.date, 'datetime', getDTLocale()) }}
                              </small>
                            </div>
                            <div class="col-9">
                              <span v-if="event.parameters.user && event.parameters.user.id">
                                <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.user.id}}" target="_blank">
                                  {{ event.parameters.player.name }}
                                </router-link>
                              </span>
                              <span v-else-if="event.parameters.player && event.parameters.player.id">
                                <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.player.id}}" target="_blank">
                                  {{ event.parameters.player.name }}
                                </router-link>
                              </span>
                              <span class="badge badge-primary small" v-if="event.event === 'server.message'">
                                <i class="fal fa-comment "></i> Server
                              </span>
                              <span class="badge badge-primary small" v-else-if="event.event === 'server.directmessage'">
                                <i class="fal fa-comment "></i> => {{event.parameters.target}}
                              </span>
                              <span class="c-feed-text" v-if="event.event !== 'player.death'">
                                {{ localizeEvent(event) }}
                              </span>
                              <span class="c-feed-text" v-else>
                                <template v-if="event.parameters.murderer">
                                  {{ localizeEvent(event) }}
                                  <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.murderer.id}}" target="_blank">
                                    {{ event.parameters.murderer.name }}
                                  </router-link>
                                  ({{event.parameters.kill.weapon}}, {{event.parameters.kill.distance}}m)
                                </template>
                                <template v-else>
                                  {{ localizeEvent(event) }}
                                </template>
                              </span>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </b-tab>
                </b-tabs>
              </div>
              <div class="c-feed-col" v-if="!is_mobile">
                <div :style="{'padding-right': mobileView ? 0 : '0.75rem'}">
                  <template v-if="permissions.message_server">
                    <div class="row mt-2">
                      <div class="col">
                        <div class="input-group bg-light">
                          <input type="text" v-on:keyup.enter="messageServer()" v-model="serverMessage" :placeholder="$t('server.dashboard.feed.send.placeholder')" class="form-control bg-transparent border-0">
                          <button class="btn btn-primary hard-border" v-on:click="messageServer()" :disabled="inProgress" :class="{'disabled': inProgress}">
                            <half-circle-spinner
                                v-if="inProgress"
                                :animation-duration="1200"
                                :size="24"
                                class="align-middle d-inline-block"
                            />
                            <template v-if="!inProgress">
                              <i class="fad fa-reply-all"></i>
                            </template>
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                  <hr>
                  <template v-if="!permissions.feed">
                    <span class="text-muted text-uppercase">
                      {{ $t('server.dashboard.feed.permissions') }}
                    </span>
                  </template>
                  <template v-else>
                    <div class="c-intersecting-row">
                      <div class="c-pin-btn">
                        <button
                            class="btn btn-rounded btn-sm"
                            :class="{'btn-outline-info': !(feedPinned), 'btn-warning': (feedPinned), 'text-black': (feedPinned)}"
                            v-on:click="feedPinned = !(feedPinned)"
                        >
                          <i class="fad fa-map-pin" />
                          {{ $t('server.dashboard.feed.pin') }}
                        </button>
                      </div>
                      <div class="c-admins" v-if="this.visibleAdmins.length">
                        <div class="avatar-group">
                          <div class="avatar-group-item" v-for="cftools_id in this.visibleAdmins" :key="cftools_id">
                            <a
                                :href="`/profile/${cftools_id}`"
                                class="d-inline-block"
                                target="_blank"
                            >
                              <img
                                  :src="admins[cftools_id].avatar"
                                  class="rounded-circle avatar-xs"
                                  v-b-tooltip.hover
                                  :title="admins[cftools_id].name"
                                  style="border:1px solid black"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="c-feed" :style="{'height': feedHeight+'px'}" ref="feed" v-on:scroll="handleScrolling">
                      <div class="row c-feed-row"
                           v-for="event in reversedFeed"
                           :key="event.id"
                           :style="{'display': displayEvent(event) ? null : 'none'}"

                           v-on:click="feedMouseEnter(event.id)"
                      >
                        <template v-if="displayEvent(event)">
                          <div class="col-3 text-center">
                            <small>
                              {{ $d(event.date, 'datetime', getDTLocale()) }}
                            </small>
                          </div>
                          <div class="col-9"> <!-- :class="{'text-danger': ['player.death', 'player.damage', 'player.suicide'].includes(event.event), 'text-white': ['user.join', 'user.leave', 'user.kicked'].includes(event.event)}" -->
                            <span v-if="event.parameters.user && event.parameters.user.id">
                              <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.user.id}}" target="_blank">
                                {{ event.parameters.player.name }}
                              </router-link>
                            </span>
                            <span v-else-if="event.parameters.player && event.parameters.player.id">
                              <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.player.id}}" target="_blank">
                                {{ event.parameters.player.name }}
                              </router-link>
                            </span>
                            <span class="badge badge-primary small" v-if="event.event === 'server.message'">
                              <i class="fal fa-comment "></i> Server
                            </span>
                            <span class="badge badge-primary small" v-else-if="event.event === 'server.directmessage'">
                              <i class="fal fa-comment "></i> => {{event.parameters.target}}
                            </span>
                            <span class="c-feed-text" v-if="event.event !== 'player.death'">
                              {{ localizeEvent(event) }}
                            </span>
                            <span class="c-feed-text" v-else>
                              <template v-if="event.parameters.murderer">
                                {{ localizeEvent(event) }}
                                <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.murderer.id}}" target="_blank">
                                  {{ event.parameters.murderer.name }}
                                </router-link>
                                ({{event.parameters.kill.weapon}}, {{event.parameters.kill.distance}}m)
                              </template>
                              <template v-else>
                                {{ localizeEvent(event) }}
                              </template>
                            </span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- Player Profile CiC -->
          <div class="card card-body c-dashboard-card" v-if="displayMode === 1">
            <div class="row">
              <button v-on:click="displayMode = 0">
                change
              </button>
              displayMode == 1
            </div>
          </div>
        </div>
      </div>
    </template>

    <b-modal
        ref="serverModal"
        size="lg"
        :title="$t('server.dashboard.server.title')"
        title-class="font-18"
        hide-footer
        content-class="hard-border"
    >
      <div class="row justify-content-center" v-if="options.worker.state === WorkerState.CONNECTED">
        <div class="col-lg-6 col-sm-12">
          <div class="alert alert-info" v-if="Object.keys(server).length <= 0">
            {{ $t('server.dashboard.server.empty') }}
          </div>
          <div class="table-responsive" v-else>
            <table class="table table-nowrap mb-0">
              <tbody>
              <tr>
                <th scope="row">
                  <h6 class="text-uppercase mb-0">
                    {{ $t('server.dashboard.server.gametime') }}
                  </h6>
                </th>
                <td>
                  {{ server.environment.gametime }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <template v-if="permissions.lock">
            <button class="btn btn-block btn-primary" v-on:click="toggleLock()" v-if="server.settings.locked === true" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
                {{ $t('server.dashboard.server.unlock') }}
              </template>
            </button>

            <button class="btn btn-block btn-outline-warning" v-on:click="toggleLock()" v-else :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
                {{ $t('server.dashboard.server.lock.button') }}
              </template>
            </button>
          </template>
          <template v-if="permissions.kick">
            <button class="btn btn-block btn-outline-danger" v-on:click="showKickAllModal()" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
                {{ $t('server.dashboard.server.kickall') }}
              </template>
            </button>
          </template>
          <template v-if="permissions.configuration && server.settings.whitelist.configured === true">
            <button class="btn btn-block btn-outline-warning" v-on:click="toggleWhitelist()" v-if="server.settings.whitelist.active === true" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
              {{ $t('server.dashboard.server.whitelist.pause') }}
              </template>
            </button>
            <button class="btn btn-block btn-primary" v-on:click="toggleWhitelist()" v-else :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
              {{ $t('server.dashboard.server.whitelist.resume') }}
              </template>
            </button>
          </template>
          <template v-if="permissions.shutdown">
            <button class="btn btn-block btn-outline-danger" v-on:click="shutdownNow()" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
              {{ $t('server.dashboard.server.shutdown.now') }}
              </template>
            </button>
            <button class="btn btn-block btn-outline-danger" v-on:click="scheduleShutdown()" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
                {{ $t('server.dashboard.server.shutdown.scheduled') }}
              </template>
            </button>
            <button class="btn btn-block btn-outline-info" v-on:click="skipShutdown()" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
                {{ $t('server.dashboard.server.shutdown.skip') }}
              </template>
            </button>
          </template>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-info">
          {{ $t('server.dashboard.server.empty') }}
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="consoleModal"
        size="lg"
        :title="$t('server.dashboard.console.title')"
        title-class="font-18"
        hide-footer
        v-if="permissions.console"
        content-class="hard-border"
        header-class="c-console-header"
        body-class="c-console-container"
    >
      <div class="c-console text-code" ref="consoleContainer">
        <div class="c-console-messages" ref="consoleMessages">
          <div class="text-muted">
            BattlEye RCon v{{console.version}} - Commands: clear, commands
          </div>
          <div class="c-console-message" v-for="message in console.messages" :key="message.id">
            {{ message.message }}
          </div>
        </div>
        <div class="c-console-prompt-row">
            <span class="c-console-prompt-header">
              <template v-if="console.pending">
                <half-circle-spinner
                    :animation-duration="1200"
                    :size="12"
                    class="align-middle"
                />
              </template>
              <template v-else>
                >
              </template>
            </span>
            <input
                ref="consolePrompt"
                class="c-console-prompt"
                autofocus="autofocus"
                v-model="console.command"
                type="text"
                autocorrect="off"
                autocapitalize="none"
                @focus="$event.target.select()"
                v-on:keyup.enter="consoleEnterHandler()"
            >
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="kickModal"
        size="md"
        :title="$t('server.dashboard.players.kick.title')"
        title-class="font-18"
        hide-footer
        v-if="permissions.kick"
    >
      <div class="row">
        <div class="col">
          <input type="text" :placeholder="$t('server.dashboard.players.kick.placeholder')" class="form-control" v-model="kick.reason">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <button class="btn btn-warning btn-block" v-on:click="kickPlayer()" :disabled="inProgress" :class="{'disabled': inProgress}">
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.dashboard.players.kick.button') }}
            </template>
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="kickAllModal"
        size="md"
        :title="$t('server.dashboard.players.kickall.title')"
        title-class="font-18"
        hide-footer
        v-if="permissions.kick"
    >
      <div class="row">
        <div class="col">
          <input type="text" :placeholder="$t('server.dashboard.players.kickall.placeholder')" class="form-control" v-model="kickAll.reason">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <button class="btn btn-warning btn-block" v-on:click="kickAllPlayers()" :disabled="inProgress" :class="{'disabled': inProgress}">
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.dashboard.players.kickall.button') }}
            </template>
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="messageModal"
        size="md"
        :title="$t('server.dashboard.players.message.title')"
        title-class="font-18"
        hide-footer
        v-if="permissions.message_private"
    >
      <div class="row">
        <div class="col">
          <input type="text" :placeholder="$t('server.dashboard.players.message.placeholder')" class="form-control" v-model="message.content">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <button class="btn btn-primary btn-block" v-on:click="messagePlayer()" :disabled="inProgress" :class="{'disabled': inProgress}">
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.dashboard.players.message.button') }}
            </template>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
